import React from 'react'
import {css} from '@emotion/core'

const textoenminipagina = css`
  padding: 1.5rem 1.5rem 1.5rem 1.4rem;
  & h3 {
    font-size: 24px;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 2rem;
   }
   & h2 {
    font-size: 40px;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 2rem;
   }
  `

const palidez = css`
  background-color: #ffffff80;
  border-radius: 8px;
  color:black;
`
const repalidez = css`
  background-color: #ffffffd0;
  border-radius: 8px;
  color: black;
`

const TextoEnMinipagina = ({palido, repalido, noMeEncuentres, children}) => (
  <div css={palido ? ( repalido ? [textoenminipagina, repalidez] : [textoenminipagina, palidez]) : textoenminipagina}>
    {noMeEncuentres ? children : <div>{children}</div>}
  </div>
)

export default TextoEnMinipagina
