import React from 'react'
import Marco from '../components/marco'
import Container from '@material-ui/core/Container'
import Minipaginas from '../components/minipaginas'
import Minipagina from '../components/minipagina'
import FotoConLeyenda from '../components/foto-con-leyenda'
import Comentarios from '../components/comentarios'
import TextoEnMinipagina from '../components/texto-en-minipagina'
import { Tips } from '../components/estilos/estilos-de-articulo'
import EspaciadorVertical from '../components/espaciador-vertical'
import { graphql } from 'gatsby'
import Navegar from '../components/navegar'

export const query = graphql`
  {
    allTipsViajandoEnAutoYaml {
      edges {
        node {
          nombre
          origen {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
// #endregion

const ViajandoEnAuto = ({ data, location }) => {
  // eslint-disable-next-line no-unused-vars
  const toFluid = nombre => {
    const edges = data.allTipsViajandoEnAutoYaml.edges
    const found = edges.find(x => x.node.nombre === nombre)
    const fluid = found.node.origen.childImageSharp.fluid
    return fluid
  }
  return (
    <Marco>
      <Tips>
        <Container maxWidth="xl">
          <Minipaginas>
            <Minipagina>
              <FotoConLeyenda fluid={toFluid('imgblogtautoViajandoenautojpg')} />
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <h2>Cómo disfrutar tu viaje en auto</h2>
                <p>
                  Viajando en auto. ¡Qué placer increíble manejar por Italia escuchando todo el
                  tiempo música italiana! Esto es posible sintonizando «Radio Italia». Música sin
                  interrupciones ¡Te lo quería contar!
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <h3>Escuchando «Radio Italia»</h3>
                <p>
                  Y también contarte la música que me acompañó en el 2012 y que me sigue gustando
                  muchísimo: «Arriverà» interpretada por Modà & Emma, en el Festival de San Remo de
                  ese año. Te la paso para que la puedas escuchar con un clic.
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda leyenda="Arriverà">
                <iframe
                  src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/67538178&amp;color=%23797979&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;show_teaser=true&amp;visual=true"
                  width="100%"
                  height="300"
                  title="Arriverà"
                  frameBorder="no"
                  scrolling="no"
                ></iframe>
              </FotoConLeyenda>
            </Minipagina>
          </Minipaginas>
          <Navegar location={location} />
          <Comentarios />
          <EspaciadorVertical altura={36} />
        </Container>
      </Tips>
    </Marco>
  )
}
export default ViajandoEnAuto
