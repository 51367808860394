import React from 'react'
import sytled from '@emotion/styled'
import { css } from '@emotion/core'
import Img from 'gatsby-image'

const foto = css`
  width: 90%;
  padding: 16px;
  padding-bottom: 2px;
  padding-left: 16px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 12px;
  margin-right: 1.5rem;
  border: 1px solid #444444;
  border-radius: 8px;
  background-color: #ffffff;
  @media (max-width: 900px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }
`
const fotoInterior = css`
  width: 100%;
  border-radius: 6px;
`
const zooming = css`
  transition: 0.9s;
  &:hover {
    transform: scale(1.3);
  }
`
const Leyenda = sytled.div`
  color: #005be6;
  font-size: 16px;
  font-family: "Satisfy",cursive;
  padding-top: 6px;
  padding-left: 4px;
`
const NativeImg = ({ zoom, src, title }) => (
  <img css={zoom ? [fotoInterior, zooming] : fotoInterior} src={src} alt="" title={title} />
)

const FluidImg = ({ zoom, fluid, title }) => (
  <Img css={zoom ? [fotoInterior, zooming] : fotoInterior} fluid={fluid} alt="" title={title} />
)

const FixedImg = ({ zoom, fixed, title }) => (
  <Img css={zoom ? [fotoInterior, zooming] : fotoInterior} fixed={fixed} alt="" title={title} />
)

const FotoConLeyenda = ({ zoom, fluid, src, fixed, title, leyenda, children }) => (
  <div css={foto}>
    {fluid ? (
      <FluidImg zoom={zoom} fluid={fluid} title={title} />
    ) : fixed ? (
      <FixedImg zoom={zoom} fixed={fixed} title={title} />
    ) : src ? (
      <NativeImg zoom={zoom} src={src} title={title} />
    ) : (
      <div>{children}</div>
    )}
    <Leyenda>{leyenda}</Leyenda>
  </div>
)

export default FotoConLeyenda
